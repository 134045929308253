import React from "react";
import { X } from "lucide-react";
import "./SidePanel.css";

const SidePanel = ({ isOpen, togglePanel }) => {
  return (
    <div className={`side-panel ${isOpen ? "open" : ""}`}>
      <button className="close-panel" onClick={togglePanel}>
        <X size={24} />
      </button>
      <h3>What is this?</h3>
      <p>
        vAI is an AI assistant created to emulate Vaibhav's personality and
        knowledge. It's just a side project I embarked on to learn more about
        this space and redo a stale personal website.
      </p>
      <h3>What can this do?</h3>
      <p>
        It will try its best to answer questions about me. It's heavily
        dependent on its training to provide accurate answers. If you prefer to
        get your information the old fashioned way, just hit the info icon on
        the right side of the top panel!
      </p>
      <h3>What did you use to build vAI?</h3>
      <p>
        The front end is built in React using{" "}
        <a
          href="https://create-react-app.dev/"
          target="_blank"
          rel="noreferrer"
        >
          create-react-app
        </a>
        . The backend is Python{" "}
        <a
          href="https://fastapi.tiangolo.com/"
          target="_blank"
          rel="noreferrer"
        >
          FastAPI
        </a>
        . It is currently using{" "}
        <a href="https://platform.openai.com/" target="_blank" rel="noreferrer">
          OpenAI
        </a>{" "}
        to generate answers to questions being asked. However, I am using some
        stuff from{" "}
        <a href="https://llamahub.ai/" target="_blank" rel="noreferrer">
          LlamaHub
        </a>{" "}
        to both train the AI and create a contextual index for that chat itself.
        The whole thing is being served using a Docker machine (backend) on an
        EC2 machine with Nginx (frontend).
      </p>
    </div>
  );
};

export default SidePanel;
