import React, { useState } from "react";
import "./ChatMessage.css";
import StopIcon from "./StopIcon";
import SpeakerIcon from "./SpeakerIcon";
import LoadingSpinner from "./LoadingSpinner";
import ChatService from "../services/ChatAPIService";

function parseBoldText(text) {
  // Regular expression to match **text**
  const boldTextRegex = /\*\*(.*?)\*\*/g;
  // Replace **text** with <strong>text</strong>
  const bolded = text.replace(boldTextRegex, "<strong>$1</strong>");
  return bolded.replace(/\n/g, "<br />");
}

const ChatMessage = ({ sender, text }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState(null);

  const parsedText = parseBoldText(text);

  const handleSpeak = async () => {
    setIsLoading(true);
    setIsSpeaking(false);

    try {
      const audioBlob = await ChatService.fetchTalkResponse(text);
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioObj = new Audio(audioUrl);
      setAudio(audioObj);

      audioObj.play();
      setIsSpeaking(true);

      audioObj.onplay = () => {
        setIsLoading(false);
      };

      audioObj.onended = () => {
        setIsSpeaking(false);
      };
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      setIsSpeaking(false);
    }
  };

  const handleStop = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setIsSpeaking(false);
    }
  };

  return (
    <div className={`message ${sender === "user" ? "user" : "bot"}`}>
      {text === "..." ? (
        <div className="typing-indicator">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <div className="message-content">
          <p dangerouslySetInnerHTML={{ __html: parsedText }}></p>
          {sender === "bot" && !isSpeaking && !isLoading && (
            <button className="speak-button" onClick={handleSpeak}>
              <SpeakerIcon />
            </button>
          )}
          {isLoading && <LoadingSpinner />}
          {isSpeaking && !isLoading && (
            <button className="stop-button" onClick={handleStop}>
              <StopIcon />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
