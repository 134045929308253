import React from "react";

export const StopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="red"
  >
    <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
  </svg>
);

export default StopIcon;
