import React, { useState, useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import ChatService from "../services/ChatAPIService";
import SidePanel from "./SidePanel";
import ContactInfo from "./ContactInfo";
import "./ChatWindow.css";
import { SendIcon } from "./SendIcon";
import { Menu, Info } from "lucide-react";

const ChatWindow = () => {
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hi! My name is vAI. " +
      "Vaibhav has been teaching me things so I can pretend to be him when people talk to me. " +
      "An assistant of sorts. If I am not doing a great job, let Vaibhav know. "+
      "Oh, and here are a few prompts to get you started!",
    },
  ]);

  const exampleMessages = [
    "What companies has Vaibhav worked at?",
    "What are Vaibhav's thoughts on good product strategy?",
    "Why does Vaibhav think outages happen?",
    "What does Vaibhav like to do in New York?",
  ];
  
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(() => {
    return window.location.pathname !== '/vAI';
  });
  const [clickedExamples, setClickedExamples] = useState([]);
  const maxMessages = 4;

  const endOfMessagesRef = useRef(null);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    // Initial height set
    updateHeight();

    // Update height on resize
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = async (messageText) => {
    if (messageText.trim()) {
      setMessages([...messages, { sender: "user", text: messageText }]);
      setInput("");
  
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "..." },
      ]);
  
      try {
        const lastMessages = messages.slice(-maxMessages);
        const data = await ChatService.fetchChatResponse(messageText, lastMessages);
  
        setMessages((prevMessages) => {
          const newMessages = prevMessages.slice(0, -1); // Remove typing indicator
          return [...newMessages, { sender: "bot", text: data.answer }];
        });
      } catch (error) {
        setError("Error occurred while fetching response");
        setTimeout(() => {
          setError("");
          setMessages((prevMessages) => {
            const newMessages = prevMessages.slice(0, -1);
            return [...newMessages];
          });
        }, 1000);
      }
    }
  };
  
  const handleSend = () => {
    sendMessage(input);
  };
  
  const handleExampleClick = (message) => {
    setClickedExamples([...clickedExamples, message]);
    sendMessage(message);
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const toggleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  return (
    <div className="chat-window" ref={chatWindowRef}>
      <SidePanel isOpen={isPanelOpen} togglePanel={togglePanel} />
      {isInfoOpen ? (
        <ContactInfo isOpen={isInfoOpen} onClose={toggleInfo} />
      ) : (
        <div className={`chat-content ${isPanelOpen ? "shifted" : ""}`}>
          <div className="chat-header">
            <button className="header-button" onClick={togglePanel}>
              <Menu size={28} />
            </button>
            <div className="header-avatar">
              <img src="/images/vAI.jpg" alt="Avatar" />
            </div>
            <button className="header-button" onClick={toggleInfo}>
              <Info size={28} />
            </button>
          </div>
          <div className="messages">
            {messages.map((msg, index) => (
              <ChatMessage key={index} sender={msg.sender} text={msg.text} />
            ))}
            <div ref={endOfMessagesRef} />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="example-messages">
          {exampleMessages
            .filter((msg) => !clickedExamples.includes(msg))
            .map((msg, index) => (
              <button
                key={index}
                className="example-message-button"
                onClick={() => handleExampleClick(msg)}
              >
                {msg}
              </button>
            ))}
        </div>
          <div className="input-area">
            <input
              type="text"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
            />
            <button className="send-button" onClick={handleSend}>
              <SendIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
