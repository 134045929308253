import React, { useEffect, useState } from "react";
import { Linkedin, Mail, CreditCard, FileText } from "lucide-react";
import "./ContactInfo.css";
import { useNavigate } from "react-router-dom";

const ActionButton = ({ icon: Icon, disabled, link, onClick, className, animationDelay }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <button
      className={`action-button ${disabled ? "disabled" : ""} ${className || ""}`}
      style={{ animationDelay: `${animationDelay}s` }}  // Set the delay dynamically
      disabled={disabled}
      onClick={handleClick}
    >
      <Icon size={24} />
    </button>
  );
};

const ContactInfo = ({ isOpen, onClose }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    }
  }, [isOpen]);

  const handleAvatarClick = () => {
    navigate('/vAI');
    onClose();
  };

  return (
    <div className={`info-popup ${isOpen ? "open" : ""}`}>
      <div className="info-content">
        <div className="profile-section">
          <img
            src="/images/profile.png"
            alt="Profile"
            className="profile-image"
          />
          <h2 className="profile-name">Vaibhav Kaul</h2>
          <div className="action-buttons">
            <ActionButton icon={FileText} link="/files/vaibhavkaul.pdf" animationDelay={0.2} />
            <ActionButton
              icon={Linkedin}
              link={"https://www.linkedin.com/in/kaulvaibhav/"}
              animationDelay={0.4}
            />
            <div className="pulsing-highlight" onClick={handleAvatarClick}>
              <div className="avatar-icon">
                <img src="/images/vAI.jpg" alt="Avatar" />
              </div>
            </div>
            <ActionButton
              icon={Mail}
              link={"mailto:vk565@nyu.edu?subject=Cool%20Website!"}
              animationDelay={0.6}
            />
            <ActionButton icon={CreditCard} link={"https://www.pcrf.net/"} animationDelay={0.8} />
          </div>
        </div>
        <div className="contact-info">
          <div className="info-item">
            <h3>Bio</h3>
            <p>
              Engineering @{" "}
              <a
                href="https://www.taptapsend.com/"
                target="_blank"
                rel="noreferrer"
              >
                Taptap Send
              </a>
              , formerly{" "}
              <a
                href="https://www.seatgeek.com/"
                target="_blank"
                rel="noreferrer"
              >
                Seatgeek
              </a>
              , &nbsp;
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                Meta
              </a>
              <br />
              <br />
              Part owner{" "}
              <a
                href="https://stretfordpaddockfc.com/"
                target="_blank"
                rel="noreferrer"
              >
                Stretford Paddock FC
              </a>
            </p>
          </div>
          <div className="info-item">
            <h3>Where I live</h3>
            <p>New York, NY</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
