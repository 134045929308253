import React from "react";

export const SpeakerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
  >
    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1-3.29-2.5-4.03v8.05c1.5-.73 2.5-2.26 2.5-4.02zm2.5 0c0 2.73-1.28 5.12-3.5 6.63V6.37c2.22 1.5 3.5 3.89 3.5 6.63z" />
  </svg>
);

export default SpeakerIcon;
